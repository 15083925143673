<template>
	<commonHeader></commonHeader>

	<div class="c-cats  w950 mauto">
		<div class="c-cats-list">
			<ul :style="{width: catsW+'px', left: (curLeftIndex * 832*-1) + 'px'}">
				<li v-for="(c,index) in cats" :key="index">
					<a :class="curCatIndex == index ? 'active': ''" href="javascript:;" @click="changeCatIndex(index)">
						{{c.name}}
						<div class="line"></div>
					</a>
				</li>
			</ul>
		</div>
		<div class="c-btns">
			<div class="left" @click="changeCats(1)"></div>
			<div class="right" @click="changeCats(2)"></div>
		</div>
	</div>

	<div v-if="!isShowNewRetail" class="w950 c-list">
		<ul>
			<li v-for="it in list.records" :key="it.id">
				<router-link class="c-item" :to="{path:'/article', query: {id: it.id, publishTime: it.publishTime}}">
					<div class="c-thumb">
						<img :src="it.image" alt />
					</div>
					<div class="info">
						<div class="title thidden">{{it.name}}</div>
						<div class="desc">{{it.teacherDescription}}</div>
						<div class="c-other">
							<div class="c-head">
								<img :src="it.teacherSquareImage ? it.teacherSquareImage : 'https://bjx-private-cdn.oss-cn-zhangjiakou.aliyuncs.com/fe/images/h5-images/avatar.png'"
									alt />
							</div>
							<div class="c-teacher">{{it.teacherName}}</div>
							<div class="c-teacher-title">{{it.teacherTitle}}</div>
							<div class="c-date">{{dates.formatDate(it.publishTime, 'yyyy-MM-dd')}}</div>
						</div>
					</div>
				</router-link>
			</li>
		</ul>
		<div v-if="list.records.length == 0" class="tc pt50">暂无数据</div>
		<a v-if="list.records.length > 0 && more" class="c-more" href="javascript:;" @click="loadMore">阅读更多文章</a>
		<div class="tc pt30 color-999" v-else>- 没有更多了 -</div>
	</div>

	<div v-else class="w950 mauto sc-container">
		<div id="w26470_landing"></div>
	</div>
	<commonFooter></commonFooter>
</template>

<script>
	import commonHeader from '../public/header.vue'
	import commonFooter from '../public/footer.vue'
	import {
		nextTick
	} from '@vue/runtime-core'
	import dates from '@/utils/dates'
	export default {
		name: 'PrPage',
		components: {
			commonHeader,
			commonFooter,
		},
		mounted() {
			const _wsc = document.createElement('script');
			_wsc.src = "//tools.prnewswire.com/zh-cn/live/26470/landing.js";
			document.head.appendChild(_wsc);

			this.getCats();
		},
		data() {
			return {
				catsW: 950,
				curCatIndex: 0,
				curLeftIndex: 0,
				cats: [],
				list: {
					records: [],
					pageSize: 15,
					pageNum: 1,
				},
				isSelect: 1,
				more: true,
				carouselList: [],
				//展示新零售内容:
				isShowNewRetail: true,
				dates,
			}
		},
		methods: {

			calCatsW() {
				this.catsW = this.cats.length * 108
			},
			changeCatIndex(i) {
				this.curCatIndex = i
				this.more = true
				if (i == 0) {
					this.isSelect = 1
				} else {
					this.isSelect = 0
				}
				this.isShowNewRetail = i == 0;

				if (i != 0) {
					this.getList()
				}
			},
			changeCats(num) {
				if (num == 2) {
					if (this.catsW - this.curLeftIndex * 802 > 802) this.curLeftIndex += 1
				} else {
					if (this.curLeftIndex * 802 > 0) this.curLeftIndex -= 1
				}
			},
			loadMore() {
				this.getList('more')
			},
			async getList(type = 'refresh') {
				if (!this.more) return
				let ln = this.list.records.length
				let res = await this.api.getArticles({
					categoryId: this.cats[this.curCatIndex].id,
					prev: type == 'more' ? this.list.records[ln - 1].publishTime : null,
					selectStat: this.isSelect,
				})
				if (res && res.code == 200) {
					if (type == 'more') {
						if (
							Object.prototype.toString.apply(res.data.records) ==
							'[object Array]'
						) {
							res.data.records.forEach((it) => {
								this.list.records.push(it)
							})
						}
					} else {
						if (
							Object.prototype.toString.apply(res.data.records) ==
							'[object Array]'
						) {
							this.list.records = res.data.records
						} else {
							this.list.records = []
						}
					}
					this.more = res.data.more
				}
			},
			async getCats() {
				let res = await this.api.getNoteLabel()
				if (res && res.code) {
					this.cats = [{
							name: '新零售',
							id: 'newRetail'
						},
						{
							name: '精选',
							id: null
						},
						...res.data.defaultFollow,
						...res.data.otherCategory,
					]
					this.getList()
					nextTick(() => {
						this.calCatsW()
					})
				}
			},
		}
	}
</script>


<style lang="less" scoped>
	.c-cats {
		font-size: 16px;
		overflow: hidden;
		position: relative;
		height: 36px;
		line-height: 36px;
		margin-top: 20px;

		.c-cats-list {
			position: relative;
			width: 832px;
			overflow: hidden;
			height: 36px;
		}

		.c-btns {
			position: absolute;
			top: 0;
			right: 0;
			z-index: 2;
			height: 36px;
			width: 72px;
			background: #fff;
			overflow: hidden;

			.left {
				background: url('~@/assets/images/arrow-l.png') no-repeat 50% 50%;
			}

			.right {
				background: url('~@/assets/images/arrow.png') no-repeat 50% 50%;
			}

			.left,
			.right {
				float: left;
				width: 36px;
				height: 36px;

				&:hover {
					background-color: #eee;
				}
			}
		}

		ul {
			position: absolute;
			left: 0;
			top: 0;
			z-index: 1;
		}

		li {
			float: left;
		}

		a {
			display: block;
			width: 104px;
			color: #333;
			text-align: center;
			position: relative;

			.line {
				width: 40px;
				position: absolute;
				bottom: 0;
				left: 50%;
				margin-left: -20px;
				display: none;
				border-bottom: 4px solid #c80000;
			}

			&.active {
				color: #c80000;

				.line {
					display: block;
				}
			}
		}
	}

	.c-list {
		width: 950px;
		margin: 0 auto;
		padding-bottom: 50px;

		ul {
			overflow: hidden;
		}

		.c-item {
			padding-top: 24px;
			width: 100%;
			display: block;
			overflow: hidden;

			.c-other {
				font-size: 13px;
				overflow: hidden;
				line-height: 40px;
				padding-top: 30px;

				>div {
					float: left;
				}

				.c-head {
					width: 30px;
					height: 30px;
					position: relative;
					top: 5px;

					img {
						width: 100%;
						height: 100%;
						border-radius: 50%;
					}
				}

				.c-teacher {
					color: #999;
					padding: 0 40px 0 20px;
				}

				.c-teacher-title {
					color: #c80000;
					padding: 0 40px 0 20px;
				}

				.c-date {
					color: #999;
				}
			}

			.c-thumb {
				width: 240px;
				height: 160px;
				float: left;

				img {
					width: 100%;
					height: 100%;
					border-radius: 4px;
				}
			}

			.info {
				width: 670px;
				padding-left: 32px;
				float: left;
				border-bottom: 1px solid #eee;
				padding-bottom: 24px;
			}

			.title {
				width: 670px;
				font-size: 20px;
				color: #333;
				line-height: 38px;
				font-weight: bold;
			}

			.desc {
				width: 670px;
				color: #666;
				font-size: 16px;
				line-height: 25px;
				height: 50px;
				overflow: hidden;
			}
		}
	}

	.c-more {
		display: block;
		margin: 40px auto 0;
		font-size: 16px;
		color: #c80000;
		width: 140px;
		line-height: 40px;
		text-align: center;
		border: 2px solid #c80000;
		border-radius: 5px;
	}

	.c-qrcode-wrap {
		position: fixed;
		font-size: 16px;
		color: #333;
		left: 50%;
		margin-left: 480px;
		bottom: 40px;
		z-index: 10;

		.c-thumb {
			width: 160px;
			height: 160px;

			img {
				width: 100%;
				height: 100%;
				border-radius: 6px;
			}
		}
	}
	.sc-container{
		padding-top: 20px;
		padding-bottom: 20px;
	}
</style>